import React, { useEffect, useState } from 'react'
import { Select, Tooltip, Popconfirm, Radio, Modal, Checkbox } from 'antd';
import Draggable from 'react-draggable';
import { Country, State, City } from "country-state-city";

import Logo from '../assets/img/logo-with-text.png'
import SampleDocFront from '../assets/img/sample-doc-front.jpg'
import SampleDocBack from '../assets/img/sample-doc-back.jpg'

import symbol1 from '../assets/img/option-icon/1.jpg'
import symbol2 from '../assets/img/option-icon/2.jpg'
import symbol3 from '../assets/img/option-icon/3.jpg'
import symbol4 from '../assets/img/option-icon/4.jpg'
import symbol5 from '../assets/img/option-icon/5.jpg'
import symbol6 from '../assets/img/option-icon/6.jpg'

import countries from "../helper/countries.json"
import { sendNotify } from '../helper/notification';

export default function Task() {
    const [imgStyle, setImgStyle] = useState({rotate: 0, zoom: 1, flip: false})
    const [docLink, setDocLink] = useState('https://guardian-northlark.com/task/doc?id=23s42sd73dg98');
    const [isCopied, setIsCopied] = useState(false);
    const [formData, setFormData] = useState({});
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);

    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');

    useEffect(() => {
        getCounty()
    }, []);

    useEffect(() => {
        getState()
    }, [formData['county']]);

    const getCounty = ()=>{
        let arr = []
        let obj = {}
        let country = Country.getAllCountries()
        country.map((option)=>{
            obj['label'] = option?.name
            obj['value'] = option?.isoCode
            arr.push(obj)
            obj = {}
        })
        setCountry(arr)
    }

    const getState = ()=>{
        let arr = []
        let obj = {}
        let state = State.getStatesOfCountry(formData['county'])
        state.map((option)=>{
            obj['label'] = option?.name
            obj['value'] = option?.isoCode
            arr.push(obj)
            obj = {}
        })
        setState(arr)
    }

    const getChange = (key, value) => {
        let obj = {...formData}
        obj[key] = value
        setFormData(obj)
    };

    function editImg(action) {
        let style = {...imgStyle}
        switch (action) {
            case 'rorateX':
                style['rotate'] -= 45
                break;
            case 'rorateY':
                style['rotate'] += 45
                break;
            case 'zoomIn':
                if (style['zoom'] !== 3) {
                    style['zoom'] += 0.5
                }
                break;
            case 'zoomOut':
                if (style['zoom'] !== 0.5) {
                    style['zoom'] -= 0.5
                }
                break;
            case 'flip':
                style['flip'] = !style['flip']
                break;
        }

        console.log(style);
        setImgStyle(style)
    }

    const copyDocLink = () => {
        navigator.clipboard.writeText(docLink)
        setIsCopied(true)
    }

    const unsupported = [
        {
          label: 'Document not supported',
          value: '1',
        },
        {
          label: 'Template not available in the confluence',
          value: '2',
        },
    ];

    const iqFail = [
        {
          label: 'Missing front',
          value: '1',
        },
        {
          label: 'No document in image',
          value: '2',
        },
        {
          label: 'Two documents uploaded',
          value: '3',
        },
    ];

    const languages = [
        {
          label: 'Latin',
          value: '1',
        },
        {
          label: 'Japaneese',
          value: '2',
        },
        {
          label: 'Chinese',
          value: '3',
        },
        {
            label: 'Cyrillic',
            value: '4',
        },
        {
            label: 'Vietnamese',
            value: '5',
        },
        {
            label: 'Korean',
            value: '6',
        }
    ];

    const escalate = [
        {
          label: 'Application error',
          value: '1',
        },
        {
          label: 'Others',
          value: '2',
        },
    ];

    const leaveCP = [
        {
          label: 'Break',
          value: '1',
        },
        {
          label: 'Training',
          value: '2',
        },
        {
          label: 'Meeting',
          value: '3',
        },
        {
          label: <span onClick={()=>setShowComment(true)}>Others</span>,
          value: '4',
        },
    ];

    const symbols = [
        {
          label: <img src={symbol1} alt="" />,
          value: '1',
        },
        {
          label: <img src={symbol2} alt="" />,
          value: '2',
        },
        {
          label: <img src={symbol3} alt="" />,
          value: '3',
        },
        {
          label: <img src={symbol4} alt="" />,
          value: '4',
        },
        {
          label: <img src={symbol5} alt="" />,
          value: '5',
        },
        {
          label: <img src={symbol6} alt="" />,
          value: '6',
        },
    ];

    const onChange = (e) =>{
        console.log(e);
    }

    const confirm = (e) => {
        console.log(e);
    };

    const stateValidation = () =>{
        if (!formData['county']) {
            sendNotify('warning', 'Please select the county first.')
        }else{
            if (state.length == 0) {
                sendNotify('warning', 'No state is available in the selected county.')
            }
        }
    }

  return (
    <div className="task">
        <div className="d-flex">
            <div className="w-75 me-3">
                <div className="task-header">
                    <h4>Harish - Classification</h4>
                    <h4>Priority</h4>
                </div>
                <div className="task-card">
                    <Draggable position={null}>
                        <div>
                            <div className={`doc-img ${imgStyle.flip ? 'flip' : ''}`} style={{rotate: `${imgStyle.rotate}deg`, transform: `scale(${imgStyle.zoom})`}}>
                                <img className='back' src={SampleDocBack} alt="" />
                                <img className='front' src={SampleDocFront} alt="" />
                            </div>
                        </div>
                    </Draggable>
                    <div className="options">
                        <div className="img-options">
                            <button onClick={()=>editImg('rorateX')}><i className="fas fa-undo"></i></button>
                            <button onClick={()=>editImg('zoomIn')}><i className="far fa-search-plus"></i></button>
                            <button onClick={()=>editImg('flip')}><i className="far fa-window-restore"></i></button>
                            <button onClick={()=>editImg('zoomOut')}><i className="far fa-search-minus"></i></button>
                            <button onClick={()=>editImg('rorateY')}><i className="fas fa-redo-alt"></i></button>
                        </div>
                        <div className='doc-link'>
                            <p>{docLink}</p>
                            <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                                <button onClick={copyDocLink}><i className={`${isCopied ? 'fad' : 'far'} fa-copy`}></i></button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-25 ms-3">
                <div className="d-flex justify-content-center mb-2">
                    <img src={Logo} className="task-logo" alt="Logo" />
                </div>
                <div className="task-options">
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        className='custom-select w-100 mb-3'
                        placeholder="Issuing Country"
                        onChange={(value)=>getChange('county', value)}
                        options={country}
                        value={formData['county']}
                    />
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        className='custom-select w-100 mb-3'
                        placeholder="Document Type"
                        onChange={(value)=>getChange('docType', value)}
                        options={[
                            {label: 'Aadhar Card', value: 'aadharCard'},
                            {label: 'Pan Card', value: 'panCard'},
                            {label: 'Driving Licence', value: 'drivingLicence'},
                            {label: 'Voter ID', value: 'voterId'},
                        ]}
                        value={formData['docType']}
                    />
                    <div className='asdfasf' onClick={stateValidation}>
                        <Select
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            className='custom-select w-100 mb-3'
                            placeholder="Issuing State/Region"
                            // disabled={state.length == 0 ? true : false}
                            onChange={(value)=>getChange('state', value)}
                            options={state}
                            value={formData['state']}
                        />
                    </div>
                    <div className='img-options'>
                        <label htmlFor="">Select a symbol below if you see it on the document</label>
                        <Radio.Group className='option' options={symbols} onChange={onChange} />
                        <Checkbox onChange={onChange}>I can see "Enhanced Driver license" or "Enhanced ID" on this document</Checkbox>
                    </div>
                    <Popconfirm
                        title="Unsupported Documents"
                        placement="left"
                        description={
                            <div>
                                <Radio.Group className='d-flex flex-column' options={unsupported} onChange={onChange} />
                            </div>
                        }
                        onConfirm={confirm}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <button className='report-options mb-3'><i className="far fa-chevron-double-left"></i><i className="fas fa-flag-alt"></i> Unsupported</button>
                    </Popconfirm>
                    <Popconfirm
                        title="IQ Fail"
                        placement="left"
                        description={
                            <div>
                                <Radio.Group className='d-flex flex-column' options={iqFail} onChange={onChange} />
                            </div>
                        }
                        onConfirm={confirm}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <button className='report-options mb-3'><i className="far fa-chevron-double-left"></i><i className="fas fa-exclamation"></i> IQ Fail</button>
                    </Popconfirm>
                    <Popconfirm
                        title="Language"
                        placement="left"
                        description={
                            <div>
                                <Radio.Group className='d-flex flex-column' options={languages} onChange={onChange} />
                            </div>
                        }
                        onConfirm={confirm}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <button className='report-options mb-3'><i className="far fa-chevron-double-left"></i><i className="fas fa-comment"></i> Language</button>
                    </Popconfirm>
                    <Popconfirm
                        title="Escalate"
                        placement="left"
                        description={
                            <div>
                                <Radio.Group className='d-flex flex-column' options={escalate} onChange={onChange} />
                            </div>
                        }
                        onConfirm={confirm}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <button className='report-options mb-3'><i className="far fa-chevron-double-left"></i><i className="fas fa-arrow-up"></i> Escalate</button>
                    </Popconfirm>
                    <div className="d-flex">
                        <Popconfirm
                            title="Leave CP"
                            placement="left"
                            description={
                                <div>
                                    <Radio.Group className='d-flex flex-column' options={leaveCP} onChange={onChange} />
                                </div>
                            }
                            onConfirm={confirm} 
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='leave-btn w-50 me-2'>Leave CP</button>
                        </Popconfirm>
                        <button className='submit-btn w-50 ms-2'>Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal title="Other" className='custom-modal' open={showComment} width={500} centered onOk={()=>setShowComment(false)} okText={'Save'} onCancel={()=>setShowComment(false)}>
            <div className="input-box">
            <label htmlFor="comments">Comments:</label>
            <textarea name="comments" id='comments' placeholder='Enter you comments' className='w-100' rows="5"></textarea>
            </div>
        </Modal>
    </div>
  )
}
