import React from 'react'
import { Switch, DatePicker } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.family = "Comfortaa, cursive";

function Dashboard() {
  
  const options = {
    responsive: true,
    plugins: {
      datalabels:{
        color: 'white'
      },
      legend: {
        position: 'top',
      },
      title: {
        display: false
      },
    },
  };

  const labels = ['01-11-2023', '02-11-2023', '03-11-2023', '04-11-2023', '05-11-2023', '06-11-2023'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Task Count',
        data: [200, 600, 400, 700, 500, 700],
        backgroundColor: 'rgba(20, 168, 0, 0.9)',
      },
      {
        label: 'AHT',
        data: [100, 400, 200, 300, 300, 500],
        backgroundColor: 'rgba(238, 115, 15, 0.9)',
      },
    ],
  };

  return (
    <div className='task p-5'>
      <div className="task-header">
        <h4>Dashboard</h4>
      </div>
      <div className="task-card dash-card d-block p-5">
        <div className="container-fluid">
          <div className="row">
            <h1>Welcome back, Harish</h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet.</p>
            <div className="col-md-4 mt-3">
              <div className="custom-card">
                <div className="flex-box">
                  <label>Timer</label>
                  <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
                </div>
                <div className="flex-box mb-0">
                  <label>Task Sound Notification</label>
                  <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
                </div>
              </div>
              <div className="custom-card mt-4">
                <div className="flex-box">
                  <label>Number of Task Processed</label>
                  <h6>147</h6>
                </div>
                <div className="flex-box">
                  <label>AHT</label>
                  <h6>50 Sec</h6>
                </div>
                <div className="flex-box">
                  <label>On CP Hours</label>
                  <h6>00:00</h6>
                </div>
                <div className="flex-box">
                  <label>Total Break Time</label>
                  <h6>00:00</h6>
                </div>
                <div className="flex-box mb-0">
                  <label>Idle Time</label>
                  <h6>00:00</h6>
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-3">
              <div className="custom-card py-4">
                <div className="input-box">
                  <label htmlFor="">Filter by date: &nbsp;</label>
                  <RangePicker className='custom-datepicker' />
                </div>
                <Bar options={options} data={data} />
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="d-flex flex-column h-100 align-items-end justify-content-between">
                <div className="custom-card">
                  <table>
                    <thead>
                      <tr><th><h5>No Production Queues</h5></th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Break</td><th>00:00</th></tr>
                      <tr><td>Training</td><th>00:00</th></tr>
                      <tr><td>Meeting</td><th>00:00</th></tr>
                      <tr><td>Others</td><th>00:00</th></tr>
                    </tbody>
                  </table>
                </div>
                <Link to={'/app/task'}><button className='start-btn'>Start</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;