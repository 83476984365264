import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { sendNotify } from '../../helper/notification'
import { checkLogged } from '../../store/auth/authSlice'

export default function Index() {
  const dispatch = useDispatch()

  const logout = () =>{
    sendNotify('success', 'Logout successfully')
    localStorage.removeItem('nl-consular-auth')
    dispatch(checkLogged())
  }

  return (
    <div>
      <div className='base-layout'>
        <button className='logout-btn' onClick={logout}><i className="far fa-power-off"></i> Logout</button>
        <Outlet />
      </div>
    </div>
  )
}
