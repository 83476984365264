// Auth
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

//Private
import Dashboard from "../pages/dashboard";
import Task from "../pages/task";
// import Settings from "../pages/settings";

// import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    component: <Login />,
    type: 'public',
    permissions: [],
  },
  {
    name: "Login",
    path: "/login",
    component: <Login />,
    type: 'public',
    permissions: [],
  },
  {
    name: "Register",
    path: "/register",
    component: <Register />,
    type: 'public',
    permissions: [],
  },
  {
    name: "Dashboard",
    path: "dashboard",
    component: <Dashboard />,
    type: 'private',
    permissions: ['SA'],
  },
  {
    name: "Task",
    path: "task",
    component: <Task />,
    type: 'private',
    permissions: ['F'],
  },
  // {
  //   name: "Settings",
  //   path: "settings",
  //   icon: "fa-cog",
  //   component: <Settings />,
  //   type: 'private',
  //   permissions: ['SA'],
  //   showInMenu: true,
  // },
  // {
  //   name: "Error 404",
  //   path: "*",
  //   icon: "",
  //   component: <NotFound />,
  //   type: 'public',
  //   permissions: [],
  //   showInMenu: false,
  // }
];

export default routes;
